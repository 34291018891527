/* src/styles.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@media only screen and (min-width: 800px) {
    body {
        background: linear-gradient(180deg, #fee8e2, #fef4df);
        background-repeat: no-repeat;
    }
    #root {
        max-width: 500px;
        margin: 0 auto;
    }
    .ant-drawer-content-wrapper {
        max-width: 500px !important;
        margin: auto;
    }
}
.category-modal {
    .ant-drawer-title {
        display: none;
    }
}
.addon-repeat-modal,
.addon-modal{
    .ant-drawer-content-wrapper{
        height: 520px!important;
    }
}
.input-wrapper{
    .ant-select-selector{
        border-radius: 0.75rem;
    }
}